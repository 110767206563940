import { css } from 'lit';

// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`
  .url-input::part(base) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  sl-card {
      text-align: center;
      max-width: 80vw;
      min-width: 80vw;
      margin-bottom: 10px;
   }
  @media(min-width: 1000px) {
    sl-card {
      text-align: center;
      max-width: 70vw;
      min-width: 50vw;
    }
  }
  .listen-card::part(body) {
      text-align: center;
    }
    main {
      text-align: center;
    }
`;