import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('trono-footer')
export class Footer extends LitElement {
  static get styles() {
    return css`
      footer {
        color: white;
        width: 100vw - 20px;
        background-color: rgba(2, 43, 58, 0.8);
        gap: 0.5rem;
        padding: 10px;
        font-family: 'Aleo', serif;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      a {
        color: white;
        text-decoration: none;
        display: inline-block;
      }
        span {
        text-align: center;
        word-break: break-word;
        }
    `;
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <footer>
        <span>&#169; trono.stream - ${__BUILD_NUM__}.${__COMMIT_HASH__}</span>
        <div>
          <a href="/privacy">privacy policy</a> -
          <a href="/terms">terms of use</a>
        </div>
      </footer>
    `;
  }
}
